import me from '@/api/modules/me'

const state = {
	userData: null,
	teams: [],
	currentTeam: null
};

const getters = {
	getUser(state) {
		return state.userData
	},
	teams(state) {
		return state.teams
	},
	currentTeam(state) {
		return state.teams.filter(t => t.Id === state.currentTeam)[0]
	}
};

const mutations = {
	userData(state, data) {
		state.userData = data
	},
	userTeams(state, data) {
		state.teams = data

		if (state.currentTeam === null || state.teams.filter(t => t === state.currentTeam).length === 0) {
			state.currentTeam = state.teams[0].Id
		}
	},
	currentTeam(state, team_id) {
		state.currentTeam = team_id
	}
};

const actions = {
	logout({state}) {
		state.userData = null
	},
	async afterLogin({commit, dispatch}) {
		let agent = await me.getUser()
		commit("userData", agent)

		let teams = await me.getTeams()
		commit("userTeams", teams)
	},
}

export default {
	name: 'User',
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
